import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { baseURL, getProjectById } from "../../redux/api";
import PreviewImageModal from "./PreviewImageModal";
import { RotatingLines } from "react-loader-spinner";

const ProjectDetailsPopup = ({
  open,
  setOpen,
  projectId,
  serviceIcon,
  textColour,
  showProjectCost,
}) => {
  const [isCloseFocused, setIsCloseFocused] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    if (open) {
      getProjectById(projectId)
        .then((res) => {
          setProjectDetails(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setProjectDetails(null);
      setLoading(true);
    }
  }, [open, projectId]);

  return (
    <>
      <PreviewImageModal
        open={imageModalOpen}
        setOpen={setImageModalOpen}
        projectImages={projectDetails?.images}
        selectedImageIndex={selectedImageIndex}
        setSelectedImageIndex={setSelectedImageIndex}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="z-50"
          onClose={imageModalOpen ? () => {} : setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 overflow-y-auto">
            <div className="absolute right-0 top-0 min-h-full text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white text-left shadow-xl transform transition-all sm:max-w-lg min-h-screen sm:w-96">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      style={{
                        boxShadow: isCloseFocused
                          ? `0 0 0 3px ${textColour} inset`
                          : "",
                      }}
                      onClick={() => setOpen(false)}
                      onFocus={() => setIsCloseFocused(true)}
                      onBlur={() => setIsCloseFocused(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Project Details
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center h-screen">
                      <RotatingLines
                        strokeColor={textColour}
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                  ) : (
                    projectDetails && (
                      <div className="border-t border-gray-200 overflow-y-auto">
                        <div className="mx-4 my-3 bg-gray-50 p-5 rounded-lg">
                          <div className="flex items-center mb-3">
                            <div className="w-14 h-14 flex items-center justify-center bg-gray-200 rounded-full mr-3 text-xs">
                              <img
                                alt={projectDetails?.service?.icon}
                                src={`${baseURL}s3/images/${serviceIcon}`}
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                            <div>
                              <h3
                                className="text-base font-semibold"
                                style={{
                                  color: textColour,
                                }}
                              >
                                {projectDetails?.service?.name}
                              </h3>
                              <p className="text-gray-500 text-sm">
                                {
                                  projectDetails?.service?.workTypes?.find(
                                    (workType) =>
                                      workType?._id === projectDetails?.workType
                                  )?.name
                                }
                              </p>
                            </div>
                          </div>
                          <h3
                            className="text-base font-semibold"
                            style={{
                              color: textColour,
                            }}
                          >
                            {projectDetails?.company?.name}
                          </h3>
                          <p className="text-gray-500 text-sm">
                            <span className="font-bold">Date Completed:</span>{" "}
                            {projectDetails.date.split("T")[0]}
                          </p>
                          <p className="mt-1 text-gray-500 text-sm">
                            <span className="font-bold">Address:</span>{" "}
                            {projectDetails?.address}
                          </p>
                          {showProjectCost && (
                            <p className="mt-1 text-gray-500 text-sm">
                              <span className="font-bold">
                                {projectDetails?.costLabel}:
                              </span>{" "}
                              $
                              {new Intl.NumberFormat("en-US").format(
                                projectDetails?.cost
                              )}
                            </p>
                          )}
                        </div>
                        <div className="mx-4 my-3 bg-gray-50 p-5 rounded-lg">
                          <h3
                            className="text-base font-semibold"
                            style={{
                              color: textColour,
                            }}
                          >
                            Project Details
                          </h3>
                          {projectDetails?.data?.map(
                            (item, index) =>
                              (item?.value || item?.value === 0) && (
                                <div
                                  key={index}
                                  className="py-1 border-t border-gray-300 flex items-center justify-between"
                                >
                                  <h3 className="text-base text-gray-500 text-opacity-60">
                                    {item?.label}
                                  </h3>
                                  <p className="text-gray-800 text-base">
                                    {typeof item?.value !== "object" ? (
                                      item?.value
                                    ) : item?.value?.value &&
                                      item?.value?.unit ? (
                                      <span>{`${item?.value?.value} ${item?.value?.unit}`}</span>
                                    ) : null}
                                  </p>
                                </div>
                              )
                          )}

                          <div className="py-3 border-t border-gray-300 flex items-center justify-around flex-wrap">
                            {projectDetails?.images?.map((img, index) => (
                              <img
                                key={img.key}
                                src={`${baseURL}s3/images/${img?.key}`}
                                alt="Project Img"
                                className="my-2 cursor-pointer w-32 h-24 object-cover"
                                onClick={() => {
                                  setImageModalOpen(true);
                                  setSelectedImageIndex(index);
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ProjectDetailsPopup;
