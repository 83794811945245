import React, { useEffect } from "react";

const PropertyType = ({
  formData,
  handleChange,
  setIsNextDisabled,
}) => {
  const propertyTypes = ["Residential", "Commercial"];
  useEffect(() => {
    setIsNextDisabled(!formData?.propertyType);
  }, [formData?.propertyType, setIsNextDisabled]);
  return (
    <>
      <div
        className="grid grid-cols-1 gap-3"
        role="group"
        aria-labelledby="my-radio-group"
      >
        {propertyTypes?.map((propertyType, index) => (
          <div className="bg-slate-100 rounded-lg p-3" key={index}>
            <label className="block text-sm text-gray-700">
              <input
                className="appearance-none w-4 h-4 border border-gray-400 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none"
                type="radio"
                name="propertyType"
                value={propertyType}
                checked={formData?.propertyType === propertyType}
                onChange={handleChange}
              />
              <span className="ms-2">{propertyType}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertyType;
