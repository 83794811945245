import React, { useEffect } from "react";

const VerifyHuman = ({
  formData,
  handleChange,
  setIsNextDisabled,
}) => {
  useEffect(() => {
    setIsNextDisabled(!(formData?.firstName && formData.lastName));
  }, [formData?.firstName, formData?.lastName, setIsNextDisabled]);
  return (
    <>
      <div className="h-full w-full flex items-center justify-start">
        <div className="w-full">
          <form>
            <div className="grid md:grid-cols-2 gap-4">
              {/* First Name */}
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
              />

              {/* Last Name */}
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyHuman;
