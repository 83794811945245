import React, { useEffect } from "react";

const AverageElectricBill = ({
  formData,
  setFormData,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  useEffect(() => {
    if (formData?.avgElectricBill) setIsNextDisabled(false);
    else setIsNextDisabled(true);
  }, [formData?.avgElectricBill, setIsNextDisabled]);
  return (
    <>
      <div className="h-full w-full flex justify-start items-center">
        <input
          id="avgElectricBill"
          name="avgElectricBill"
          type="number"
          value={formData?.avgElectricBill}
          onChange={handleChange}
          placeholder="Enter your average electric bill"
          className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
        />
      </div>
    </>
  );
};

export default AverageElectricBill;
