import React from "react";
import { Oval } from "react-loader-spinner";

const ActiveStep = ({
  steps,
  activeStep,
  formData,
  setFormData,
  appearanceSettings,
  landingPageDetails,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
  company,
}) => {
  const StepComponent = steps?.[activeStep - 1]?.component;

  return (
    <>
      {StepComponent ? (
        <StepComponent
          steps={steps}
          formData={formData}
          setFormData={setFormData}
          appearanceSettings={appearanceSettings}
          landingPageDetails={landingPageDetails}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
          company={company}
        />
      ) : (
        <div className="flex justify-center items-center h-full">
          <Oval
            height={28}
            width={28}
            color={appearanceSettings?.primaryColour}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor={appearanceSettings?.secondaryColour}
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      )}
    </>
  );
};

export default ActiveStep;
