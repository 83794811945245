import React, { useState, useEffect, useRef, Suspense } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  getBrandsOfferedByCompany,
  getCompanyById,
  getSolarLandingPageById,
  getRelevantSolarProjects,
  getLandingPageServicesByCompanyId,
  storePartnersPageResopnseInDatabase,
  updateContactAndZipStatus,
  getUserLocation,
} from "../../../../redux/api";
import Slider from "react-slick";
import ActiveStep from "../../components/ActiveStep";
import RoofArea from "../../../../assets/images/RoofArea.jpg";
import RoofingCardImage from "../../../../assets/images/RoofingCardImage.png";
import People1 from "../../../../assets/images/People1.jpg";
import People2 from "../../../../assets/images/People2.jpg";
import People3 from "../../../../assets/images/People3.jpg";
import logo from "../../../../assets/images/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import { PiMedalFill } from "react-icons/pi";
import { FaRankingStar } from "react-icons/fa6";
import {
  getFullStateName,
  getStateCoordinates,
} from "../../../../utils/states";
import { formatNumber, formatSystemSize } from "../../../../utils/numbers";
import { isValidZipCode } from "../../../../utils/regexValidations";
import { formatPhoneNumber } from "../../../../utils/phoneNumbers";
import RoofType from "../../components/RoofType";
import Levels from "../../components/Levels";
import VerifyHuman from "../../components/VerifyHuman";
import Email from "../../components/Email";
import PhoneNumber from "../../components/PhoneNumber";
import SolarProjectsSummary from "../../components/SolarProjectsSummary";
import NoServiceInZip from "../../components/NoServiceInZip";
import PropertyType from "../../components/PropertyType";
import AverageElectricBill from "../../components/AverageElectricBill";
import CalculateSystemSize from "../../components/CalculateSystemSize";
import ShowSystemRange from "../../components/ShowSystemRange";
import SolarResults from "../../components/SolarResults";
import { useInView } from "react-intersection-observer";
import { GoogleMap } from "@react-google-maps/api";
import { set } from "lodash";

const Modal = React.lazy(() => import("../../../../components/Modal/Modal"));
// const GoogleMap = React.lazy(() =>
//   import("@react-google-maps/api").then((module) => ({
//     default: module.GoogleMap,
//   }))
// );

const roofData = [
  {
    systemSizeInkW: "3",
    avgCost: "$6900 - $12000",
    costAfterTaxCredit: "$4830 - $8400",
  },
  {
    systemSizeInkW: "3.5",
    avgCost: "$8050 - $14000",
    costAfterTaxCredit: "$5635 - $9800",
  },
  {
    systemSizeInkW: "4",
    avgCost: "$9200 - $16000",
    costAfterTaxCredit: "$6440 - $11200",
  },
  {
    systemSizeInkW: "4.5",
    avgCost: "$10350 - $18000",
    costAfterTaxCredit: "$7245 - $12600",
  },
  {
    systemSizeInkW: "5",
    avgCost: "$11500 - $20000",
    costAfterTaxCredit: "$8050 - $14000",
  },
  {
    systemSizeInkW: "5.5",
    avgCost: "$12650 - $22000",
    costAfterTaxCredit: "$8855 - $15400",
  },
  {
    systemSizeInkW: "6",
    avgCost: "$13800 - $24000",
    costAfterTaxCredit: "$9660 - $16800",
  },
  {
    systemSizeInkW: "6.5",
    avgCost: "$14950 - $26000",
    costAfterTaxCredit: "$10465 - $18200",
  },
  {
    systemSizeInkW: "7",
    avgCost: "$16100 - $28000",
    costAfterTaxCredit: "$11270 - $19600",
  },
  {
    systemSizeInkW: "7.5",
    avgCost: "$17250 - $30000",
    costAfterTaxCredit: "$12075 - $21000",
  },
  {
    systemSizeInkW: "8",
    avgCost: "$18400 - $32000",
    costAfterTaxCredit: "$12880 - $22400",
  },
  {
    systemSizeInkW: "8.5",
    avgCost: "$19550 - $34000",
    costAfterTaxCredit: "$13685 - $23800",
  },
  {
    systemSizeInkW: "9",
    avgCost: "$20700 - $36000",
    costAfterTaxCredit: "$14490 - $25200",
  },
  {
    systemSizeInkW: "9.5",
    avgCost: "$21850 - $38000",
    costAfterTaxCredit: "$15295 - $26600",
  },
  {
    systemSizeInkW: "10",
    avgCost: "$23000 - $40000",
    costAfterTaxCredit: "$16100 - $28000",
  },
  {
    systemSizeInkW: "10.5",
    avgCost: "$24150 - $42000",
    costAfterTaxCredit: "$16905 - $29400",
  },
  {
    systemSizeInkW: "11",
    avgCost: "$25300 - $44000",
    costAfterTaxCredit: "$17710 - $30800",
  },
  {
    systemSizeInkW: "11.5",
    avgCost: "$26450 - $46000",
    costAfterTaxCredit: "$18515 - $32200",
  },
  {
    systemSizeInkW: "12",
    avgCost: "$27600 - $48000",
    costAfterTaxCredit: "$19320 - $33600",
  },
  {
    systemSizeInkW: "12.5",
    avgCost: "$28750 - $50000",
    costAfterTaxCredit: "$20125 - $35000",
  },
  {
    systemSizeInkW: "13",
    avgCost: "$29900 - $52000",
    costAfterTaxCredit: "$20930 - $36400",
  },
  {
    systemSizeInkW: "13.5",
    avgCost: "$31050 - $54000",
    costAfterTaxCredit: "$21735 - $37800",
  },
  {
    systemSizeInkW: "14",
    avgCost: "$32200 - $56000",
    costAfterTaxCredit: "$22540 - $39200",
  },
  {
    systemSizeInkW: "14.5",
    avgCost: "$33350 - $58000",
    costAfterTaxCredit: "$23345 - $40600",
  },
  {
    systemSizeInkW: "15",
    avgCost: "$34500 - $60000",
    costAfterTaxCredit: "$24150 - $42000",
  },
];

const initialValues = {
  service: null,
  // serviceIcon: "",
  zipCode: "",
  roofType: "",
  level: "",
  avgElectricBill: "",
  propertyType: "",
  address: "",
  coordinates: null,
  addressZipCode: "",
  estimatedRoofArea: "",
  systemSizeRange: "",
  roofImage: "",
  numberOfRelevantProjects: 0,
  projects: [],
  projectsCosts: {
    min: 0,
    max: 0,
    mid: 0,
  },
  savingsData: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  partnerResponseId: "",
};

var settings = {
  lazyLoad: "progressive",
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const logosSettings = {
  lazyLoad: "progressive",
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  cssEase: "linear",
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const options = {
  disableDefaultUI: true,
  // minZoom: 10,
  maxZoom: 14,
  mapId: "a216e88580f77d70",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
  draggable: false,
};

const formatReviewCount = (count) => {
  if (count >= 1000 && count < 10000) {
    return `${(count / 1000).toFixed(1)}k+`;
  } else if (count >= 10000) {
    return `${(count / 1000).toFixed(0)}k+`;
  }
  return count;
};

const SolarLandingPageHeroSection = () => {
  const location = useLocation();
  const { id } = useParams();
  const landingPageId = new URLSearchParams(location.search)?.get(
    "landing_page_id"
  );

  const [loading, setLoading] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [userLocationError, setUserLocationError] = useState(null);
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const [showProjectsResults, setShowProjectsResults] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Select your roof type",
      component: RoofType,
      status: "current",
      completed: true,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 2,
      title: "How many levels?",
      component: Levels,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 3,
      title: "What is your average monthly electric bill?",
      component: AverageElectricBill,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 4,
      title: "Select your property type",
      component: PropertyType,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 5,
      title: "Calculate system size",
      component: CalculateSystemSize,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 6,
      title: "Estimated system size",
      component: ShowSystemRange,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 7,
      title: "Project Summary",
      component: SolarProjectsSummary,
      status: "upcoming",
      completed: false,
      nextButtonText: "See Projects",
      prevButtonText: "Back",
    },
    {
      id: 8,
      title: "Enter your name (To verify you are a real person)",
      component: VerifyHuman,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 9,
      title: "What is your email address?",
      component: Email,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 10,
      title: "What is your phone number?",
      component: PhoneNumber,
      status: "upcoming",
      completed: false,
      nextButtonText: "Agree & See Projects",
      prevButtonText: "Back",
    },
    {
      id: 11,
      title: "No Service in Zip Code",
      message: "",
      component: NoServiceInZip,
      status: "upcoming",
      completed: false,
      nextButtonText: "Yes, Contact Me",
      prevButtonText: "No, Thanks",
    },
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [company, setCompany] = useState(null);
  const [services, setServices] = useState([]);
  const [appearanceSettings, setAppearanceSettings] = useState({
    headerBGColour: "#000",
  });
  const [brandsOffered, setBrandsOffered] = useState([]);

  const [error, setError] = useState(null);
  const { ref: logosRef, inView: logosInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    // Create and inject the TrustedForm script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      (document.location.protocol === "https:" ? "https" : "http") +
      "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" +
      new Date().getTime() +
      Math.random();

    // Append the script to the body
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures it runs only once

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id && landingPageId) {
          const [companyRes, servicesRes, landingPageRes] = await Promise.all([
            getCompanyById(id),
            getLandingPageServicesByCompanyId(id),
            getSolarLandingPageById(landingPageId),
          ]);

          setCompany(companyRes.data);
          setServices(servicesRes?.data);

          const landingPageData = landingPageRes.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            service: landingPageData.service,
          }));
          setLandingPageDetails(landingPageData);

          let coordinates;
          if (landingPageData?.state !== "all") {
            coordinates = getStateCoordinates(landingPageData?.state);
          } else {
            coordinates = await new Promise((resolve) => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ lat: latitude, lng: longitude });
                  },
                  () => resolve({ lat: 40.7128, lng: -74.006 })
                );
              } else {
                resolve({ lat: 40.7128, lng: -74.006 });
              }
            });
          }
          setMapCenter(coordinates);

          // setFormData({
          //   ...formData,
          //   zipCode: landingPageData?.defaultZipCode,
          // });

          // Don't uncomment me
          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition(
          //     (position) => {
          //       const { latitude, longitude } = position.coords;
          //       const geocoder = new window.google.maps.Geocoder();
          //       geocoder.geocode(
          //         { location: { lat: latitude, lng: longitude } },
          //         (results, status) => {
          //           if (status === "OK" && results[0]) {
          //             const zipCode = results[0].address_components.find(
          //               (component) => component.types.includes("postal_code")
          //             )?.long_name;
          //             setFormData((prevFormData) => ({
          //               ...prevFormData,
          //               zipCode: zipCode || landingPageData?.defaultZipCode,
          //             }));
          //           } else {
          //             console.error("Error fetching zip code:", status);
          //           }
          //         }
          //       );
          //     },
          //     (error) => {
          //       if (error.code === error.PERMISSION_DENIED) {
          //         setFormData((prevFormData) => ({
          //           ...prevFormData,
          //           zipCode: landingPageData?.defaultZipCode,
          //         }));
          //       } else if (error.code === error.POSITION_UNAVAILABLE) {
          //         alert("Location information is unavailable.");
          //       }
          //     }
          //   );
          // } else {
          //   alert("Geolocation is not supported by this browser.");
          // }
          // Don't uncomment me ends

          setAppearanceSettings(landingPageData?.appearanceSettings || {});
          const fetchLocation = async () => {
            try {
              const response = await getUserLocation();
              setUserLocation(response?.data);
              if (response?.data) {
                const { lat, lng } = response?.data?.location;
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (status === "OK" && results[0]) {
                      const zipCode = results[0].address_components.find(
                        (component) => component.types.includes("postal_code")
                      )?.long_name;
                      if (zipCode) {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zipCode: zipCode,
                        }));
                      } else {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zipCode: landingPageData?.defaultZipCode,
                        }));
                      }
                    } else {
                      console.error("Error fetching zip code:", status);
                    }
                  }
                );
              }
            } catch (error) {
              setUserLocationError(error);
              console.log(error);
            }
          };
          fetchLocation();
        }
      } catch (error) {
        setError({
          heading: "404: Landing page not found for the specified company.",
          message:
            "We couldn't find the landing page you were looking for. Please check the URL and try again.",
        });
      } finally {
        setLoading(false);
      }
    };

    // fetchLocation();
    fetchData();
  }, [id, landingPageId]);

  useEffect(() => {
    const fetchBrands = async () => {
      if (logosInView && id) {
        const brandsRes = await getBrandsOfferedByCompany(id);
        setBrandsOffered(brandsRes?.data);
      }
    };

    fetchBrands();
  }, [logosInView, id]);

  useEffect(() => {
    if (formData?.zipCode && isValidZipCode(formData?.zipCode)) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [formData?.zipCode, setIsNextDisabled]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      return updatedFormData;
    });
  };
  const systemSizeRange = (val) => {
    const match = val.match(/([\d.]+)kW\s*-\s*([\d.]+)kW/);
    return match
      ? { min: parseFloat(match[1]), max: parseFloat(match[2]) }
      : {};
  };

  const getRelatedProjects = async () => {
    const requestData = {
      serviceId: formData?.service,
      workTypes: landingPageDetails?.workTypes,
      // Don't uncomment me
      // roofType: formData?.roofType,
      // Don't uncomment me ends
      propertyType: formData?.propertyType,
      // Don't uncomment me
      // estimatedRoofArea: formData?.estimatedRoofArea,
      // Don't uncomment me ends
      systemSizeMinRange: systemSizeRange(formData?.systemSizeRange)?.min,
      systemSizeMaxRange: systemSizeRange(formData?.systemSizeRange)?.max,
      companyId: company?._id,
      loanRate: landingPageDetails?.loanRate,
      loanTerm: landingPageDetails?.loanTerm,
    };

    if (landingPageDetails?.state !== "all") {
      requestData.state = landingPageDetails?.state;
    }

    await getRelevantSolarProjects(requestData)
      .then(({ data }) => {
        setFormData({
          ...formData,
          numberOfRelevantProjects: data?.projects?.length || 0,
          projects: data?.projects || [],
          projectsCosts: {
            min: data?.minCost,
            minMonthlyPayment: data?.minMonthlyPayment,
            max: data?.maxCost,
            maxMonthlyPayment: data?.maxMonthlyPayment,
            mid: data?.midCost,
            midMonthlyPayment: data?.midMonthlyPayment,
          },
          serviceIcon: data?.serviceIcon,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showProjectResults = async (savePhone = true) => {
    const result = await storePartnersPageResopnseInDatabase(company?._id, {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      customerEmail: formData?.email,
      phoneNo: savePhone ? formData?.phoneNo : "",
      address: formData?.address,
      partnerId: company?._id,
      landingPageId: landingPageId,
      serviceId: landingPageDetails?.service,
      trustedFormUrl: document.querySelector(
        "input[name='xxTrustedFormCertUrl']"
      )?.value,
      url: window.location.href,
      queryDetails: {
        roofType: formData?.roofType,
        level: formData?.level,
        roofAge: formData?.roofAge,
        propertyType: formData?.propertyType,
        estimatedRoofArea: formData?.estimatedRoofArea,
        avgElectricBill: formData?.avgElectricBill,
        systemSizeMinRange: systemSizeRange(formData?.systemSizeRange)?.min,
        systemSizeMaxRange: systemSizeRange(formData?.systemSizeRange)?.max,
      },
    });

    const isZipInList = landingPageDetails?.zipCodesListId?.zipCodes?.includes(
      formData?.addressZipCode
    );

    if (isZipInList) {
      setShowProjectsResults(true);
      setOpen(false);
      return true;
    } else {
      let stepTitle = "";
      let stepMessage = "";

      if (!isZipInList) {
        stepTitle = "Sorry!";
        stepMessage = `<p>Unfortunately <span className=\"font-bold\">${
          company?.name
        }</span> does not service your area. Would you like Pro-Mapper to contact you at
        ${
          formData.phoneNo
            ? formatPhoneNumber(formData?.phoneNo)
            : formData?.email
        }
        and help connect you with a qualified partner in your area?
      </p>`;
      }

      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[10].title = stepTitle;
        updatedSteps[10].message = stepMessage;
        return updatedSteps;
      });
      setFormData({ ...formData, partnerResponseId: result?.data?._id });

      return false;
    }
  };

  const handleNextStep = async (savePhone = true) => {
    if (activeStep === 6) {
      getRelatedProjects();
    } else if (activeStep === 10) {
      await showProjectResults(savePhone);
      if (!open) return;
    } else if (activeStep === 11) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: true,
      });
      setFormData(initialValues);
      setOpen(false);
      setActiveStep(1);
      return;
    }

    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[activeStep - 1].status = "completed";
      updatedSteps[activeStep].status = "current";
      return updatedSteps;
    });
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = async () => {
    if (activeStep === 10) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: false,
      });
      setFormData(initialValues);
      setOpen(false);
      setActiveStep(1);
    }
    if (activeStep > 1) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[activeStep - 1].status = "upcoming";
        updatedSteps[activeStep - 2].status = "current";
        return updatedSteps;
      });
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleFinish = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold text-red-600 mb-4">
          {error?.heading}
        </h1>
        <p className="text-lg text-gray-700 mb-8">{error?.message}</p>
      </div>
    );
  }

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        initialValues={initialValues}
        formData={formData}
        appearanceSettings={appearanceSettings}
        setFormData={setFormData}
        handleChange={handleChange}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        handleFinish={handleFinish}
        isNextDisabled={isNextDisabled}
        showProjectResults={showProjectResults}
      >
        <ActiveStep
          steps={steps}
          activeStep={activeStep}
          formData={formData}
          landingPageDetails={landingPageDetails}
          company={company}
          appearanceSettings={appearanceSettings}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      </Modal>
      <div
        className="relative isolate overflow-hidden"
        style={{ background: appearanceSettings?.headerBGColour }}
      >
        {showProjectsResults ? (
          <SolarResults
            formData={formData}
            logosSettings={logosSettings}
            brandsOffered={brandsOffered}
            company={company}
            landingPageDetails={landingPageDetails}
            appearanceSettings={appearanceSettings}
          />
        ) : (
          <>
            <svg
              aria-hidden="true"
              className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
              <defs>
                <pattern
                  x="50%"
                  y={-1}
                  id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                  width={100}
                  height={100}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-600">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                width="100%"
                height="100%"
                strokeWidth={0}
              />
            </svg>
            <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col gap-y-8 items-start lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-10 lg:px-8 lg:py-24">
              <div className="lg:flex lg:items-center lg:h-full w-full flex">
                <div className="flex flex-col gap-y-6">
                  <h1 className="text-4xl font-bold text-white sm:text-6xl">
                    {landingPageDetails?.headerTitle ||
                      "AI Powered Solar Calculator"}
                  </h1>
                  <p className="text-lg font-normal text-white sm:text-xl">
                    {landingPageDetails?.headerDescription
                      ? landingPageDetails?.headerDescription
                      : "Calculate your system size and see projects in your local area"}
                  </p>
                  <form
                    className="md:flex md:items-center md:gap-x-4"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    <div>
                      <input
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        value={formData?.zipCode}
                        onChange={handleChange}
                        placeholder="Enter your zip"
                        className="block w-72 rounded-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm"
                      />
                    </div>
                    <button
                      onClick={() => setOpen(true)}
                      type="button"
                      disabled={isNextDisabled}
                      className={`md:mt-0 mt-4 flex items-center gap-x-2 rounded-full px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 text-nowrap`}
                      style={{
                        backgroundColor: isNextDisabled
                          ? "#ccc"
                          : appearanceSettings?.primaryColour || "#00458f",
                        cursor: isNextDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      {landingPageDetails?.buttonText
                        ? landingPageDetails?.buttonText
                        : "Find Projects"}
                      <FaCircleArrowRight className="w-5" />
                    </button>
                  </form>
                  {company?.reviewScore && company?.reviewCount && (
                    <div className="md:flex md:gap-6">
                      <div className="flex -space-x-4 rtl:space-x-reverse">
                        <img
                          className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                          src={People1}
                          alt=""
                        />
                        <img
                          className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                          src={People2}
                          alt=""
                        />
                        <img
                          className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                          src={People3}
                          alt=""
                        />
                        <a
                          className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800"
                          href="#"
                        >
                          +{formatReviewCount(company?.reviewCount) - 3}
                        </a>
                      </div>
                      <div className="flex flex-col gap-y-2 md:mt-0 mt-4">
                      <div className="flex">
                            {Array.from({ length: 5 }).map((_, index) => {
                              const score = company?.reviewScore || 0;
                              const fraction = Math.max(
                                0,
                                Math.min(1, score - index)
                              ); // Determines how much of the star to fill

                              return (
                                <div
                                  key={index}
                                  className="relative w-4 h-4 me-1"
                                >
                                  {/* Empty Star */}
                                  <svg
                                    className="absolute top-0 left-0 w-full h-full"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 22 20"
                                  >
                                    <path
                                      d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      fill="#d1d5db"
                                    />
                                  </svg>

                                  {/* Filled Star */}
                                  <svg
                                    className="absolute top-0 left-0 w-full h-full"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                    style={{
                                      clipPath: `inset(0 ${
                                        100 - fraction * 100
                                      }% 0 0)`,
                                    }} // Dynamic clipping
                                  >
                                    <path
                                      d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                                      fill="#facc15"
                                    />
                                  </svg>
                                </div>
                              );
                            })}
                          </div>
                        <div className="flex text-white">
                          <p className="ms-1 text-sm font-medium">{`${company?.reviewScore}/5`}</p>
                          <p className="ms-1 text-sm font-medium">out of</p>
                          <p className="ms-1 text-sm font-medium">{`${formatReviewCount(
                            company?.reviewCount
                          )} reviews`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="relative w-full mt-8 sm:mt-12 lg:mt-0 flex-shrink-0 flex-grow">
                <GoogleMap
                  options={options}
                  mapContainerStyle={{
                    width: "100%",
                    height: "400px",
                    borderRadius: "1rem",
                  }}
                  center={mapCenter}
                  zoom={8}
                ></GoogleMap>
                <svg
                  className="absolute"
                  style={{
                    top: "260px",
                    left: "50px",
                    fill: appearanceSettings?.primaryColour,
                  }}
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                </svg>
                <svg
                  className="absolute"
                  style={{
                    top: "200px",
                    left: "150px",
                    fill: appearanceSettings?.primaryColour,
                  }}
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                </svg>
                <svg
                  className="absolute"
                  style={{
                    top: "330px",
                    left: "200px",
                    fill: appearanceSettings?.primaryColour,
                  }}
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                </svg>
                {company?.launchYear && (
                  <div className="absolute bg-gray-100 h-20 rounded-md -top-5 -left-4 shadow-lg py-4 px-2 grid grid-cols-3 gap-2">
                    <div className="flex justify-center items-center rounded-full bg-red-200 w-12 h-12 col-span-1">
                      <PiMedalFill className="text-orange-600 w-6 h-6" />
                    </div>
                    <div className="col-span-2 flex flex-col">
                      <h3 className="font-extrabold text-xl">{`${
                        new Date().getFullYear() - company?.launchYear
                      }+`}</h3>
                      <p className="font-normal text-gray-500 text-sm">
                        Years Experience
                      </p>
                    </div>
                  </div>
                )}

                <div className="absolute bg-gray-100 rounded-md bottom-3 right-3 shadow-lg h-14 px-1 grid grid-cols-3 gap-2">
                  <div className="flex flex-col justify-center items-center col-span-1">
                    <div className="w-10 h-10 rounded-md bg-sky-500 flex justify-center items-center">
                      <FaRankingStar className="text-white w-6 h-6" />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col justify-center">
                    <h3 className="font-bold text-xl">98%</h3>
                    <p className="font-normal text-gray-500 text-sm">
                      Satisfaction Rate
                    </p>
                  </div>
                </div>
                <div className="absolute top-16 right-5">
                  <div
                    className="rounded-2xl p-1"
                    style={{
                      background: `linear-gradient(to right, ${appearanceSettings?.primaryColour}, transparent)`,
                    }}
                  >
                    <div className="bg-white rounded-[calc(1.5rem-1px)]">
                      <img
                        className="w-80 h-56 object-cover border-4 border-white mx-auto rounded-2xl"
                        src={
                          landingPageDetails?.headerImage
                            ? `${baseURL}s3/images/${landingPageDetails?.headerImage}`
                            : ""
                        }
                        alt="Map"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SolarLandingPageHeroSection;
