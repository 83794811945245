import React, { useEffect } from "react";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";

const NoServiceInZip = ({
  steps,
}) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: steps[steps.length - 1]?.message || "",
      }}
    />
  );
};

export default NoServiceInZip;
