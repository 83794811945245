import React, { useEffect } from "react";
import { storePartnersPageResopnseInDatabase } from "../../../redux/api";
import { isPhoneNumberValid } from "../../../utils/regexValidations";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";

const PhoneNumber = ({
  formData,
  handleChange,
  handleSubmit,
  handleNextStep,
  setIsNextDisabled,
  company,
}) => {
  useEffect(() => {
    if (formData?.phoneNo && isPhoneNumberValid(formData?.phoneNo)) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [formData?.phoneNo, setIsNextDisabled]);
  return (
    <>
      <div className="h-full w-full flex items-start justify-center flex-col gap-y-3">
        <p className="text-md text-gray-700">
          By clicking Agree & See Projects I am providing my ESIGN signature and
          express written consent agreement to permit {company?.name} or parties
          calling on their behalf to contact me on the number provided below for
          marketing purposes including the use of automated technology , SMS/MMS
          messages, AI generative voices, and prerecorded and/or artificial
          voice messages. I acknowledge my consent is not required to obtain any
          goods or service and I can see the projects{" "}
          <button
            onClick={() => handleNextStep(false)}
            className="text-blue-500 underline"
          >
            here
          </button>
          .
        </p>
        <input
          id="phone"
          name="phoneNo"
          type="tel"
          maxLength={14}
          value={formatPhoneNumber(formData.phoneNo)}
          onChange={handleChange}
          placeholder="Enter your phone number"
          className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
        />
      </div>
    </>
  );
};

export default PhoneNumber;
