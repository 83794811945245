export const formatNumber = (number) => {
  return Math.trunc(number)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatSystemSize = (number) => {
  // Round to 2 decimal places and if the last digit is 0, remove it. Also add commas to the number.
  return number
    ?.toFixed(2)
    ?.replace(/\.0$/, "")
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
