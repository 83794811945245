import { Link } from "react-router-dom";
import Logo from "../../assets/pm consumer/Logo 1.png";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { FiFacebook } from "react-icons/fi";

export default function Footer() {
  return (
    <footer className="bg-[#F3FAF6]">
      <div className=" ">
        <div className="py-5 lg:px-10 md:px-10 px-0">
          <div className="py-12 mx-6">
            <div className="grid-1 grid gap-5 grid-cols-12">
              <div className="lg:col-span-4 col-span-12">
                <div className="lg:text-left">
                  <Link to="/" className="">
                    <img
                      className="mx-5 md:mx-auto lg:mx-auto mb-10 h-24 "
                      src={Logo}
                      alt="logo"
                    />
                  </Link>
                  <p className="lg:w-72 md:w-72 w-full text-lg font-light "></p>
                </div>
              </div>
              <div className="lg:col-span-4 col-span-12">
                <div className="px-3 lg:px-0 flex items-center justify-start lg:justify-center">
                  <div>
                    <p className="text-gray-700 text-2xl font-bold">
                      Useful Links
                    </p>
                    <ul className="text-gray-700 mt-6 space-y-1 text-sm">
                      <li className=" flex items-center justify-start">
                        <Link
                          to="/privacy-policy"
                          className="text-lg font-light"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className=" flex items-center justify-start">
                        <Link
                          to="/terms-and-conditions"
                          className="text-lg font-light"
                        >
                          Terms and Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-4 col-span-12">
                <div className="px-3 lg:px-0 flex items-center justify-start lg:justify-center">
                  <div>
                    <p className="text-gray-700 text-2xl font-bold">
                      Contact Info
                    </p>

                    <ul className="text-gray-700 mt-6 space-y-2 text-sm">
                      <li className=" flex items-start justify-start">
                        <FaRegEnvelopeOpen className="text-lg mt-1" />
                        <a
                          href="mailto:info@pro-mapper.com"
                          className="ml-3 text-lg font-light"
                        >
                          info@pro-mapper.com
                        </a>
                      </li>
                      <li className=" flex items-start justify-start">
                        <IoLogoInstagram className="text-2xl mt-1" />
                        <a
                          href="https://www.instagram.com/promapperhq/"
                          target="_blank"
                          className="ml-3 text-lg font-light"
                        >
                          @promapperhq
                        </a>
                      </li>
                      <li className=" flex items-start justify-start">
                        <FiFacebook className="text-2xl mt-1" />
                        <a
                          href="https://www.instagram.com/promapperhq/"
                          target="_blank"
                          className="ml-3 text-lg font-light"
                        >
                          ProMapperHQ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-300 pt-3 text-center ">
            <span className="font-light text-lg ">
              © 2024 Pro-Mapper. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
