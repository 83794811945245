import React, { useEffect, useState } from "react";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";

function ViewFilterFields({
  filterData,
  filter,
  setFilter,
  projectDataFilters,
  setProjectDataFilters,
}) {
  const [selectFields, setSelectFields] = useState([]);

  useEffect(() => {
    if (filterData?.length > 0) {
      const selectFields = filterData
        ?.filter((field) => {
          return field?.type === "select";
        })
        ?.map((field) => {
          return {
            name: field?.name,
            selected:
              field?.subType === "multiple"
                ? projectDataFilters
                    ?.find((projectDataFilter) => {
                      return projectDataFilter?.name === field?.name;
                    })
                    ?.value?.map((value) => {
                      return {
                        value: value,
                        label: field?.options?.find((option) => {
                          return option._id === value;
                        })?.name,
                        name: field?.name,
                      };
                    })
                : {
                    value: projectDataFilters?.find((projectDataFilter) => {
                      return projectDataFilter?.name === field?.name;
                    })?.value,
                    label: field?.options?.find((option) => {
                      return (
                        option._id ===
                        projectDataFilters?.find((projectDataFilter) => {
                          return projectDataFilter?.name === field?.name;
                        })?.value
                      );
                    })?.name,
                    name: field?.name,
                  },
          };
        });
      setSelectFields(selectFields);
    }
  }, [filterData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = filterData?.type === "number" ? Number(value) : value;
    setFilter({ ...filter, [name]: value });
  };
  const handleSelect = (selectedOption) => {
    const { name, value } = selectedOption;
    setProjectDataFilters([
      ...projectDataFilters?.filter((filterField) => {
        return filterField?.name !== name;
      }),
      {
        ...projectDataFilters?.find((filterField) => {
          return filterField?.name === name;
        }),
        name: name,
        value: value,
      },
    ]);
  };
  const handleMultiSelect = (selectedOptions, field) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setProjectDataFilters([
      ...projectDataFilters?.filter((filterField) => {
        return filterField?.name !== field?.name;
      }),
      {
        ...projectDataFilters?.find((filterField) => {
          return filterField?.name === field?.name;
        }),
        name: field?.name,
        value: selectedValues,
      },
    ]);
    setSelectFields([
      ...selectFields?.filter((selectField) => {
        return selectField?.name !== field?.name;
      }),
      {
        ...selectFields?.find((selectField) => {
          return selectField?.name === field?.name;
        }),
        name: field?.name,
        selected: selectedOptions,
      },
    ]);
  };

  return (
    <>
      {filterData?.length > 0 ? (
        filterData?.map((field, index) => (
          <div key={index} className="col-span-6">
            {field?.type === "select" ? (
              <>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor={field?.name}
                    className="mt-1.5 block text-sm font-medium leading-6 text-gray-900"
                  >
                    {field?.label}
                  </label>
                </div>
                <div className="mt-0.5">
                  <Select
                    isMulti={field?.subType === "multiple"}
                    name={field?.name}
                    id={field?.name}
                    closeMenuOnSelect={true}
                    value={
                      selectFields?.find((selectField) => {
                        return selectField?.name === field?.name;
                      })?.selected
                    }
                    onChange={
                      field?.subType === "multiple"
                        ? handleMultiSelect
                        : handleSelect
                    }
                    options={field?.options?.map((option, index) => ({
                      value: option._id === undefined ? option : option._id,
                      label: option.name === undefined ? option : option.name,
                      name: field?.name,
                    }))}
                    placeholder={`Select ${field?.label.replace(
                      /([a-z])([A-Z])/g,
                      "$1 $2"
                    )}`}
                  />
                </div>
              </>
            ) : field?.type === "date" ? (
              <>
                <label
                  htmlFor={field?.name}
                  className="mt-1.5 block text-sm font-medium leading-6 text-gray-900"
                >
                  {field?.label}
                </label>
                <div className="mt-0.5">
                  <Datepicker
                    name={field?.name}
                    id={field?.name}
                    value={
                      projectDataFilters?.find((filterField) => {
                        return filterField?.name === field?.name;
                      })?.value || ""
                    }
                    onChange={(value) => {
                      setProjectDataFilters([
                        ...projectDataFilters?.filter((filterField) => {
                          return filterField?.name !== field?.name;
                        }),
                        {
                          ...projectDataFilters?.find((filterField) => {
                            return filterField?.name === field?.name;
                          }),
                          name: field?.name,
                          value: value,
                        },
                      ]);
                    }}
                    placeholder="Date Range"
                    asSingle={field.subType === "equals"}
                  />
                </div>
              </>
            ) : field?.type === "number" ? (
              <>
                <label
                  htmlFor={field?.name}
                  className="mt-1.5 block text-sm font-medium leading-6 text-gray-900"
                >
                  {field?.label}
                </label>
                {field.subType === "range" ? (
                  <div className="mt-0.5">
                    <div className="flex justify-between items-center gap-4">
                      <input
                        type="number"
                        placeholder="Min Range"
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                        value={
                          projectDataFilters?.find((filterField) => {
                            return filterField?.name === field?.name;
                          })?.minValue || ""
                        }
                        onChange={(e) => {
                          setProjectDataFilters([
                            ...projectDataFilters?.filter((filterField) => {
                              return filterField?.name !== field?.name;
                            }),
                            {
                              ...projectDataFilters?.find((filterField) => {
                                return filterField?.name === field?.name;
                              }),
                              name: field?.name,
                              minValue: e.target.value,
                              maxValue:
                                projectDataFilters?.find((filterField) => {
                                  return filterField?.name === field?.name;
                                })?.maxValue || "",
                            },
                          ]);
                        }}
                      />
                      <input
                        type="number"
                        placeholder="Max Range"
                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                        value={
                          projectDataFilters?.find((filterField) => {
                            return filterField?.name === field?.name;
                          })?.maxValue || ""
                        }
                        onChange={(e) => {
                          setProjectDataFilters([
                            ...projectDataFilters?.filter((filterField) => {
                              return filterField?.name !== field?.name;
                            }),
                            {
                              ...projectDataFilters?.find((filterField) => {
                                return filterField?.name === field?.name;
                              }),
                              name: field?.name,
                              minValue:
                                projectDataFilters?.find((filterField) => {
                                  return filterField?.name === field?.name;
                                })?.minValue || "",
                              maxValue: e.target.value,
                            },
                          ]);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mt-0.5">
                    <input
                      type="number"
                      name={field?.name}
                      id={field?.name}
                      value={
                        projectDataFilters?.find((filterField) => {
                          return filterField?.name === field?.name;
                        })?.value || ""
                      }
                      onChange={(e) => {
                        setProjectDataFilters([
                          ...projectDataFilters?.filter((filterField) => {
                            return filterField?.name !== field?.name;
                          }),
                          {
                            ...projectDataFilters?.find((filterField) => {
                              return filterField?.name === field?.name;
                            }),
                            name: field?.name,
                            value: e.target.value,
                          },
                        ]);
                      }}
                      autoComplete="off"
                      placeholder={field?.label}
                      className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                    />
                  </div>
                )}
              </>
            ) : (
              <div className=" col-span-6">
                <label
                  htmlFor={field?.name}
                  className="mt-1.5 block text-sm font-medium leading-6 text-gray-900"
                >
                  {field?.label}
                </label>
                <div className="mt-0.5">
                  <input
                    type={field?.type}
                    name={field?.name}
                    id={field?.name}
                    value={filter[field?.name] || ""}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder={field?.label}
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                  />
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center col-span-12">
          <h1 className="text-base text-center px-4 text-gray-400">
            Please select a service
          </h1>
        </div>
      )}
    </>
  );
}

export default ViewFilterFields;
