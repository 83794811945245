import React from "react";
import { Dialog } from "@headlessui/react";
import { MdOutlineCancel } from "react-icons/md";

const Modal = ({
  open,
  setOpen,
  steps,
  activeStep,
  appearanceSettings,
  children,
  isNextDisabled,
  handleNextStep,
  handlePrevStep,
}) => {
  return (
    <Dialog open={open} onClose={() => {}} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative md:h-[450px] w-full max-w-[40rem] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
            <div>
              <Dialog.Title
                as="h3"
                className="px-4 pt-5 pb-2 text-lg font-semibold text-gray-900"
              >
                {steps[activeStep - 1]?.title}
              </Dialog.Title>
              <div>
                <MdOutlineCancel
                  className="absolute top-5 right-4 text-gray-300 hover:text-gray-400 cursor-pointer w-8 h-8"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
            <div className="px-4 pb-4 pt-2 overflow-y-auto md:h-80">
              {children}
            </div>

            <div className="px-4 py-4 flex justify-between flex-row-reverse gap-3 border-t">
              <button
                type="button"
                onClick={handleNextStep}
                disabled={isNextDisabled}
                style={{
                  backgroundColor: isNextDisabled
                    ? "#ccc"
                    : appearanceSettings?.primaryColour || "#00458f",
                  cursor: isNextDisabled ? "not-allowed" : "pointer",
                }}
                className="rounded-full px-8 py-3 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-blue-500"
              >
                {steps[activeStep - 1]?.nextButtonText || "Next Step"}
              </button>

              <button
                type="button"
                onClick={handlePrevStep}
                disabled={activeStep === 1}
                className={`rounded-full px-8 py-3 text-sm font-semibold shadow-sm ring-1 ring-inset 
                  ${
                    activeStep === 1
                      ? "bg-gray-300 cursor-not-allowed text-gray-500"
                      : "bg-white text-gray-900 hover:bg-gray-50"
                  } sm:mt-0 sm:w-auto`}
              >
                {steps[activeStep - 1]?.prevButtonText || "Back"}
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
